import React, { useState } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { snakeCase } from 'lodash';
import { css } from '@emotion/react';
import { createUtmQuery } from '@lib/searchQuery';
import FrontpageLink from '@components/FrontpageLink';
import BackgroundImage from 'gatsby-background-image';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import TestimonialBlock from '@components/ui/TestimonialBlock';
import Page, { getPageElements } from '@components/Page';
import Hero from '@components/theme/Hero';
import Section from '@components/theme/Section';

export default function EbookTemplate({
  data: {
    page
  },
  pageContext: { language, refs, layouts, configuration }
}) {
  const [isModalOpen, setModalState] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const { title, subtitle, stepsTitle, step1, step2, step3, cover, ctaButton, modalTitle, formFirstName, formLastName, formCompanyName, formEmail, hook, thankYouUrl } = getPageElements(page);
  const steps = [step1, step2, step3];
  const toggleModal = () => setModalState(!isModalOpen);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isBusy) {
      setBusy(true);
      fetch(hook, {
        method: 'post',
        body: new FormData(event.target)
      }).then(response => response.json()).then(response => {
        if (response.status === 'success') {
          window.scrollTo(0, 0);
          window.location.replace(thankYouUrl);
        }
        else
          window.alert('Error: Something went wrong - try again later.');
      });
    }
  };
  return (
    <Page>
      <Modal size="lg" isOpen={isModalOpen} toggle={toggleModal}>
        <form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">{formEmail}</label>
              <input name="email" type="email" required placeholder={formEmail} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">{formFirstName}</label>
              <input name="firstName" type="text" required placeholder={formFirstName} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">{formLastName}</label>
              <input name="lastName" type="text" required placeholder={formLastName} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">{formCompanyName}</label>
              <input name="companyName" type="text" required placeholder={formCompanyName} className="form-control" />
            </div>
          </ModalBody>
          <ModalFooter>
            <button disabled={isBusy} type="submit" className="btn btn-primary">{ctaButton}</button>
          </ModalFooter>
        </form>
      </Modal>
      <Hero title={title} subtitle={subtitle} divider={{ variant: 5, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <Section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-5 mb-5">
              <GatsbyImage
                fluid={cover.localFile.sharp.fluid}
              />
            </div>
            <div className="col-12 col-xl-7 mb-5">
              <div className="d-flex flex-column h-100 justify-content-between">
                <h2 className="text-center mb-4">{stepsTitle}</h2>
                <div className="mb-4">
                  {steps.map((step, index) =>
                    <div className="d-flex mb-4" key={index}>
                      <div className="process-circle bg-primary" />
                      <div className="d-flex align-items-center ml-3">
                        <h5 className="mb-0">{`${index + 1}. ${step}`}</h5>
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    onClick={toggleModal}
                    className="btn btn-primary btn-lg"
                  >
                    {ctaButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;